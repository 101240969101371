/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QChecked = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.87777 1.06762L3.66199 7.64981C3.49995 7.8214 3.23827 7.82029 3.07754 7.64731L0.119873 4.46445C-0.0408601 4.29147 -0.0398269 4.01213 0.122215 3.84054L0.415646 3.52984C0.577688 3.35825 0.839365 3.35936 1.0001 3.53234L3.37565 6.08875L9.00461 0.127966C9.16665 -0.0436187 9.42833 -0.0425157 9.58906 0.130466L9.88011 0.443708C10.0409 0.616689 10.0398 0.896033 9.87777 1.06762Z" fill="#4695A6" />
  </svg>

);

export default QChecked;
