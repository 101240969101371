import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { OfferType } from "entities/checkout";
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

type ToastContextProps = {
  openToast: (props: ToastProps) => void
}

type ToastProps = {
  message: string
  title?: string
  type?: 'error' | 'warning' | 'success' | 'info'
}

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastProps>({} as ToastProps);

  const openToast = useCallback((props: ToastProps) => {
    setToast({
      type: 'success',
      ...props,
    });
    setOpen(true);
  }, []);

  const config = useMemo(() => ({ openToast }), [openToast])

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <ToastContext.Provider value={config}>
      {children}
      <Snackbar open={open} anchorOrigin={{ horizontal: "right", vertical: "top" }} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast.type} sx={{ width: '100%' }}>
          {toast.title && <AlertTitle>{toast.title}</AlertTitle>}
          {toast.message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  )
};

export const useToastContext = () => useContext(ToastContext);
