import QModalButton from "components/atoms/QModalButton";
import QModalDescriptions from "components/atoms/QTypography/QModalDescriptions";
import QModalTitle from "components/atoms/QTypography/QModalTitle";
import { useRecoverPasswordContext } from "context/RecoverPasswordContext";
import { useEffect, useRef, useState } from "react";
import ReactPinField, { PinField } from "react-pin-field";
import { useToastContext } from "context/ToastContext";
import AuthApi from 'services/auth';
import { AxiosError } from "axios";

const descriptionText = 'Insira o código de acesso que foi enviado para o seu e-mail para prosseguir.';

const SecondStep = () => {
  const { goToNextStep, email, code, setCode } = useRecoverPasswordContext();
  const { openToast } = useToastContext();
  const inputRef = useRef<PinField>(null!);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.currentTarget.reportValidity();
    try {
      await AuthApi.checkPasswordRecoverCode(email, code)
      goToNextStep();
    } catch (error) {
      if ((error as AxiosError)?.response?.data?.message === 'Auth::Users::ResetPassword::CodeError') {
        openToast({
          message: 'Código digitado inválido.',
          type: 'error'
        });

        return;
      }

      openToast({
        message: 'Ocorreu um erro ao tentar confirmar o código.',
        type: 'error'
      });
    }
  };

  useEffect(() => {
    // add required validation for each input
    if (inputRef.current) {
      inputRef.current.style.display = 'flex';
      inputRef.current.style.gap = '8px';

      inputRef.current.inputs.forEach((element) => {
        element?.setAttribute('required', 'true');
        element?.setAttribute('autocomplete', 'chrome-off');
      })
    }
  }, [])

  return (
    <>
      <QModalTitle style={{ marginBottom: 30 }}>
        Esqueceu sua senha?
      </QModalTitle>
      <QModalDescriptions style={{ marginTop: 8, fontSize: 14, }}>
        {descriptionText}
      </QModalDescriptions>

      <form onSubmit={handleSubmit}>
        <div className="PinFieldContainer">
          <ReactPinField
            length={6}
            className="PinFieldRecover"
            ref={inputRef}
            style={{ display: 'flex', marginTop: 20, width: '100%' }}
            onComplete={(value) => setCode(value)}
          />
        </div>

        <QModalButton type="submit" style={{ marginTop: 20 }}>
          Confirmar
        </QModalButton>
      </form>
    </>
  );
};

export default SecondStep;

