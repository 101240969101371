/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QCloseButton = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="currentColor" d="M7.27298 6.00019L11.1191 2.15407L11.9122 1.36092C12.0293 1.24391 12.0293 1.05378 11.9122 0.936779L11.0636 0.0881278C10.9466 -0.0288758 10.7565 -0.0288758 10.6395 0.0881278L6.00019 4.7274L1.36092 0.0877527C1.24391 -0.0292509 1.05378 -0.0292509 0.936779 0.0877527L0.0877527 0.936404C-0.0292509 1.05341 -0.0292509 1.24354 0.0877527 1.36054L4.7274 6.00019L0.0877527 10.6395C-0.0292509 10.7565 -0.0292509 10.9466 0.0877527 11.0636L0.936404 11.9122C1.05341 12.0293 1.24354 12.0293 1.36054 11.9122L6.00019 7.27298L9.84631 11.1191L10.6395 11.9122C10.7565 12.0293 10.9466 12.0293 11.0636 11.9122L11.9122 11.0636C12.0293 10.9466 12.0293 10.7565 11.9122 10.6395L7.27298 6.00019Z" />
  </svg>
);

export default QCloseButton;
