import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH, PAUSE,
  PERSIST,
  PURGE,
  REGISTER, REHYDRATE,
} from 'redux-persist';
import persistedReducer from './persistor';

export const store = configureStore({
  reducer: persistedReducer ?? {},
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = store.dispatch;
