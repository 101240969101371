import { Reducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducers from './rootReducers';

const persistConfig = {
  key: 'PersistedState',
  version: 1,
  storage,
  whitelist: ['user'],
};

const persistedReducer: Reducer = persistReducer(persistConfig, rootReducers);

export default persistedReducer;
