import React, { ReactNode } from 'react';
import './styles.css';

export interface QModalTitleProps {
  children: ReactNode,
  style?: React.CSSProperties | undefined,
}

const QModalTitle = (Props: QModalTitleProps) => {
  const { children, style } = Props;

  return (
    <h1 className="QModalTitle" style={{ ...style }}>
      {children}
    </h1>
  );
};

export default QModalTitle;
