import React, { ReactElement } from 'react';
import './styles.css';

export interface QModalDoubleDividerProps {
  children?: ReactElement[] | ReactElement
}

const QModalDoubleDivider = (Props: QModalDoubleDividerProps) => {
  const { children } = Props;

  return (
    <div className="QModalDividerContainer">
      <hr className="QModalDividerLeft" />
      {children}
      <hr className="QModalDividerRight" />
    </div>
  );
};

export default QModalDoubleDivider;
