import axios, { AxiosRequestConfig } from 'axios';
import { useAppDispatch } from 'stores';
import { callRefreshToken, clearAuth } from 'stores/user';
import { getToken, getRefreshToken } from 'services/sessions';

const RETRY_LIMIT = 0;
const UNAUTHORIZED = 401

let refreshing: Promise<any> | null = null;

const clearSession = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useAppDispatch(clearAuth());
  localStorage.removeItem('persist:PersistedState');
  window.location.replace('/');
}

const Api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

Api.interceptors.request.use((config: any) => {
  let { headers } = config;
  const token = getToken();
  const retryCount = config.retryCount || 0;

  if (token) {
    headers = headers || {};
    headers.Authorization = `Bearer ${token}`;
  }

  return {
    ...config,
    headers,
    retryCount,
  };
});

Api.interceptors.response.use((response) => response, async (error) => {
  const { response, config } = error;
  const { retryCount } = config;

  if (response.status !== UNAUTHORIZED) {
    return Promise.reject(error);
  }

  if (retryCount > RETRY_LIMIT) {
    return Promise.reject(error);
  }

  const refreshToken = getRefreshToken();

  if (!refreshToken) {
    clearSession();
    return Promise.reject(error);
  }

  refreshing ||= useAppDispatch(callRefreshToken(refreshToken)).finally(() => {
    refreshing = null;
  });

  const retryConfig = {
    ...config,
    retryCount: retryCount + 1
  };

  try {
    await refreshing;
    return Promise.resolve(Api(retryConfig));
  } catch {
    clearSession()
    return Promise.reject(error);
  }
});

export default Api;
