import React, { ReactElement } from 'react';
import './styles.css';

export interface QModalBodyRightProps {
  children?: ReactElement[] | ReactElement | boolean
}

const QModalBodyRight = (Props: QModalBodyRightProps) => {
  const { children } = Props;

  return (
    <div className="QModalBodyRight">
      {children}
    </div>
  );
};

export default QModalBodyRight;
