import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

export enum Steps {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  FOURTH = 'FOURTH'
};

export type NextStepsKeys = keyof typeof Steps;
export type NextSteps = {
  [k in NextStepsKeys]: Steps
};

const toNextStep = (step: Steps) => {
  const steps: NextSteps = {
    FIRST: Steps.SECOND,
    SECOND: Steps.THIRD,
    THIRD: Steps.FOURTH,
    FOURTH: Steps.FOURTH
  };

  return steps[step as unknown as NextStepsKeys];
}

type RecoverPasswordContextProps = {
  goToNextStep: () => void
  setStep: React.Dispatch<React.SetStateAction<Steps>>
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setCode: React.Dispatch<React.SetStateAction<string>>
  step: Steps,
  email: string
  code: string,
}

const RecoverPasswordContext = createContext<RecoverPasswordContextProps>({} as RecoverPasswordContextProps);

export const RecoverPasswordContextProvider = ({ children }: { children: ReactNode }) => {
  const [step, setStep] = useState<Steps>(Steps.FIRST);
  const [email, setEmail] = useState<string>(null!);
  const [code, setCode] = useState<string>(null!);

  const goToNextStep = useCallback(() => setStep(toNextStep(step)), [step]);

  const config = useMemo(() => ({
    setStep,
    step,
    goToNextStep,
    setCode,
    setEmail,
    email,
    code,
  }), [
    step,
    setStep,
    goToNextStep,
    setCode,
    setEmail,
    email,
    code,
  ]);

  return (
    <RecoverPasswordContext.Provider value={config}>
      {children}
    </RecoverPasswordContext.Provider>
  )
};

export const useRecoverPasswordContext = () => useContext(RecoverPasswordContext);
