/* eslint-disable arrow-body-style */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course } from '../../entitys/course';

type Tag = {
  id: string;
  name: string;
  slug: string;
  courses: Array<Course>;
}

const initialState: Tag = {
  id: '',
  name: '',
  slug: '',
  courses: [],
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setTag: (state, action: PayloadAction<Tag>) => {
      return { ...action.payload };
    },
  },
});

export const { setTag } = tagSlice.actions;

export default tagSlice.reducer;
