import { OfferType } from 'entities/checkout';
import api from 'services/index';

async function show(id: string): Promise<OfferType> {
  const response = await api.get(`/checkout/offers/${id}`);

  const {
    price,
    reference_price,
    benefits,
    item,
    name,
    images,
    gateway,
  } = response.data;

  return {
    id,
    price: { cents: price.cents },
    referencePrice: { cents: reference_price.cents },
    benefits,
    item,
    name,
    images,
    gateway: {
      ...gateway,
      config: {
        publicKey: gateway.config.public_key,
      }
    }
  };
}

export default {
  show,
};
