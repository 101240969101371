/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';
import classNames from 'classnames';
import './styles.css';

const QModalButton:
  React.FC<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  > = (Props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement
    >, HTMLButtonElement>) => {
    const {
      children, style, disabled, onClick, className, type, ...rest
    } = Props;

    function createRipple(event: React.MouseEvent) {
      const button = event.currentTarget;
      const circle = document.createElement('span');
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
      const ripple = button.getElementsByClassName('ripple')[0];

      if (ripple) {
        ripple.remove();
      }

      circle.style.width = `${diameter}px`;
      circle.style.height = `${diameter}px`;
      circle.style.left = `${event.nativeEvent.offsetX - radius}px`;
      circle.style.top = `${event.nativeEvent.offsetY - radius}px`;
      circle.classList.add('ripple');

      button.appendChild(circle);
    }

    return (
      <button
        className={classNames('QModalButton', className)}
        type={type === 'submit' ? 'submit' : 'button'}
        onClick={(e) => {
          createRipple(e);
          if (onClick) {
            onClick(e);
          }
        }}
        style={{ ...style }}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  };

export default QModalButton;
