import QCheckCircle from 'assets/icons/QCheckCircle';
import { OfferBenefits } from 'entities/checkout';
import React from 'react';
import './styles.css';

export interface QLoginOfferBenefits {
  benefits: OfferBenefits[]
}

const QLoginOfferBenefits = (Props: QLoginOfferBenefits) => {
  const { benefits } = Props;

  return (
    <div className="QOfferBenefitsContainer">
      {benefits.map((el, index) => (
        <div className="QOfferBenefit" key={index.toString()}>
          <QCheckCircle className="QBenefitIcon" />
          <span dangerouslySetInnerHTML={{ __html: el.text }} />
        </div>
      ))}
    </div>
  );
};

export default QLoginOfferBenefits;
