import QCheckoutProtected from 'assets/icons/QCheckoutProtected';
import QErrorCircle from 'assets/icons/QErrorCircle';
import QSuccessCircle from 'assets/icons/QSuccessCircle';
import { Status } from './types';

const ProtectedIcon = () => (
  <div className="InputProtectedContainer">
    <QCheckoutProtected className="QStatusIconProtected" />
    <span className="InputCompraSegura">COMPRA SEGURA</span>
  </div>
)

const StatusComponents = {
  "Success": QSuccessCircle,
  "Error": QErrorCircle,
  "Protected": ProtectedIcon,
};

const StatusIcon = ({ status }: { status: Status }) => {
  const IconComponent = StatusComponents[status];

  return (<IconComponent className="QInputStatusIcon"/>);
};

export default StatusIcon;
