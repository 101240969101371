import { useSelector } from "react-redux";
import { RootState } from "stores/rootReducers";

const useAuth = () => {
  const user = useSelector((state: RootState) => state.user);

  return user;
};

export default useAuth;
