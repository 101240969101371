import { OfferType, PaymentType } from "entities/checkout";
import { off } from "process";
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import OfferService from 'services/checkout/offers';

type PaymentContextProps = {
  setPayment: React.Dispatch<React.SetStateAction<PaymentType>>
  setPaymentType: React.Dispatch<React.SetStateAction<PaymentTypeSelected>>
  payment: PaymentType
  paymentType: PaymentTypeSelected
}

export type PaymentTypeSelected = 'pix' | 'boleto' | 'credit_card';

const PaymentContext = createContext<PaymentContextProps>({} as PaymentContextProps);

export const PaymentContextProvider = ({ children }: { children: ReactNode }) => {
  const [payment, setPayment] = useState<PaymentType>(null!);
  const [paymentType, setPaymentType] = useState<PaymentTypeSelected>('credit_card');

  const config = useMemo(() => ({
    setPayment,
    payment,
    paymentType,
    setPaymentType,
  }), [
    payment,
    setPayment,
    setPaymentType,
    paymentType
  ]);

  return (
    <PaymentContext.Provider value={config}>
      {children}
    </PaymentContext.Provider>
  )
};

export const usePaymentContext = () => useContext(PaymentContext);
