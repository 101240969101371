import React, { ReactElement } from 'react';
import './styles.css';

export interface QModalBodyRightProps {
  children?: ReactElement[] | ReactElement,
}

const QModalBodyLeft = (Props: QModalBodyRightProps) => {
  const { children } = Props;

  return (
    <div className="QModalBodyLeft">
      {children}
    </div>
  );
};

export default QModalBodyLeft;
