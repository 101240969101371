/* eslint-disable arrow-body-style */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Home } from '../../entitys/home';
import { CATEGORIES_PLACEHOLDER } from './placeholders';

const initialState: Home = {
  currentCategory: 'any',
  ctaUrl: null,
  categoriesList: CATEGORIES_PLACEHOLDER,
  products: [],
  filteredProducts: [],
};

export const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<any>) => {
      const products = action.payload.map((course: any) => (
        {
          id: course.id,
          cover: course.images.card,
          slug: course.slug,
          name: course.name,
          tags: course.tags.map((tag: any) => tag.name),
          duration: Math.round(course.duration / 60),
          price: course.offer.price.cents / 100.0,
          referencePrice: course.offer.reference_price.cents / 100.0,
          students: 100,
          partner: course.productor.name,
          url: `/${course.productor.slug}/cursos/${course.slug}`,
        }
      ));

      return {
        ...state,
        products,
        filteredProducts: products,
      }
    },
    setCurrentCategory: (state, action: PayloadAction<string>) => {
      const categoryValue = action.payload;

      return {
        ...state,
        currentCategory: categoryValue
      }
    },
    setCurrentCtaUrl: (state, action: PayloadAction<string|null>) => {
      const urlValue = action.payload;

      return {
        ...state,
        ctaUrl: urlValue
      }
    },
    filterProductsByCategory: (state, action: PayloadAction<string>) => {
      const categoryValue = action.payload;

      if (categoryValue === 'any') return {
        ...state,
        filteredProducts: state.products
      }

      const mappedProducts = state.products.filter((product) => {
        if (product.tags.includes(categoryValue)) return product;
        return null;
      })

      return {
        ...state,
        filteredProducts: mappedProducts
      }
    },
  },
});

export const { setCurrentCategory, setCurrentCtaUrl, filterProductsByCategory, setProducts } = courseSlice.actions;

export default courseSlice.reducer;
