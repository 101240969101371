import classNames from 'classnames';
import React, { ReactNode } from 'react';
import './styles.css';

export interface QModalLinkProps {
  children: ReactNode,
  onClick?: () => void,
  className?: string,
}

const QModalLink = (Props: QModalLinkProps) => {
  const { children, onClick, className } = Props;

  return (
    <span className={classNames('QModalLink', className)} role="presentation" onClick={onClick}>
      {children}
    </span>
  );
};

export default QModalLink;
