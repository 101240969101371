const googleSignIn = (callback: any) => {

  const existingScript = document.getElementById('googleSignIn');

  if (!existingScript) {
    const script = document.createElement('script');

    script.id = 'googleSignIn';
    script.src = 'https://apis.google.com/js/client:platform.js?onload=start';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.gapi.load('auth2', () => {
        window.auth2 = window.gapi.auth2.init({
          client_id: process.env.REACT_APP_GOOGLE_KEY,
        });
      });

      if (callback) {
        callback();
      }
    };

    document.body.appendChild(script);
  }

  if (existingScript && callback) {
    callback();
  }
};

export default googleSignIn;
