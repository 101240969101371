/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QSuccessCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4207 0C4.89779 0 0.420654 4.47714 0.420654 10C0.420654 15.5229 4.89779 20 10.4207 20C15.9435 20 20.4207 15.5229 20.4207 10C20.4207 4.47714 15.9435 0 10.4207 0ZM10.4208 18.7096C5.63599 18.7096 1.71115 14.8364 1.71115 9.99996C1.71115 5.21516 5.58434 1.29028 10.4208 1.29028C15.2057 1.29028 19.1305 5.16347 19.1305 9.99996C19.1305 14.7848 15.2573 18.7096 10.4208 18.7096ZM8.85453 14.8416L16.1319 7.62257C16.3217 7.43439 16.3229 7.12801 16.1347 6.9383L15.7939 6.59475C15.6057 6.40503 15.2994 6.40382 15.1096 6.59201L8.51929 13.1295L5.738 10.3258C5.54982 10.136 5.24345 10.1348 5.05373 10.323L4.71018 10.6638C4.52046 10.852 4.51925 11.1583 4.70744 11.3481L8.17026 14.8389C8.35844 15.0286 8.66481 15.0298 8.85453 14.8416Z" fill="#36AA76" />
  </svg>
);

export default QSuccessCircle;
