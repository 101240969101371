import QModalButton from "components/atoms/QModalButton";
import QModalInput from "components/atoms/QModalInput";
import QModalDescriptions from "components/atoms/QTypography/QModalDescriptions";
import QModalTitle from "components/atoms/QTypography/QModalTitle";
import { useRecoverPasswordContext } from "context/RecoverPasswordContext";
import { useToastContext } from "context/ToastContext";
import AuthApi from 'services/auth';

const descriptionText = `É só preencher o campo abaixo com o e-mail cadastrado na plataforma que enviaremos um código de acesso para você cadastrar uma nova senha.`;

const FirstStep = () => {
  const { goToNextStep, setEmail } = useRecoverPasswordContext();
  const { openToast } = useToastContext();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    try {
      const email = formData.get('email') as string;

      setEmail(email);
      await AuthApi.askRecoverPasswordCode(email);
      goToNextStep();
    } catch (error) {
      openToast({
        message: 'Ocorreu um erro ao tentar enviar o código.',
        type: 'error'
      });
    }
  }

  return (
    <>
      <QModalTitle style={{ marginBottom: 30 }}>
        Esqueceu sua senha ?
      </QModalTitle>
      <QModalDescriptions style={{ marginTop: 0, lineHeight: '19px' }}>
        {descriptionText}
      </QModalDescriptions>

      <form onSubmit={handleSubmit}>
        <QModalInput name="email" type="email" required placeholder="E-mail" style={{ marginTop: 32 }} />

        <QModalButton
          type="submit"
          style={{ marginTop: 16 }}
        >
          Enviar Código
        </QModalButton>
      </form>
    </>
  );
};

export default FirstStep;
