import React from 'react';
import QModalContainer from '../QModalContainer';
import './styles.css';

export interface QLoadingProps {
  open: boolean;
}

const QLoading = (Props: QLoadingProps) => {
  const { open } = Props;

  return (
    <QModalContainer open={open} type="Loading">
      <div className="loader-container">
        <div className="loader" />
      </div>
    </QModalContainer>
  );
};
export default QLoading;
