import api from '../index';

async function getOrders() {
  return api.get('/checkout/orders');
}

async function getOrder(orderId: string) {
  return api.get(`/checkout/orders/${orderId}`);
}

async function createRefund(orderId: string, motive: string) {
  return api.post(`/checkout/orders/${orderId}/refunds`, { motive });
}

export default {
  getOrders,
  getOrder,
  createRefund,
};
