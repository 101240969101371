import QLogotype from 'assets/images/QLogotype';
import QModalCloseButton from 'components/atoms/QModalCloseButton';
import React from 'react';
import './styles.css';

export interface QModalHeaderProps {
  fnCallback: () => void
}

const QModalHeader = (Props: QModalHeaderProps) => {
  const { fnCallback } = Props;

  return (
    <div className="QModalHeader">
      <QLogotype className="QModalLogo" />
      <QModalCloseButton fnCallback={fnCallback} />
    </div>
  );
};

export default QModalHeader;
