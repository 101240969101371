/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QErrorCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.4209 0C4.8967 0 0.420898 4.47581 0.420898 10C0.420898 15.5242 4.8967 20 10.4209 20C15.9451 20 20.4209 15.5242 20.4209 10C20.4209 4.47581 15.9451 0 10.4209 0ZM10.4209 18.7097C5.63461 18.7097 1.71122 14.8347 1.71122 10C1.71122 5.21371 5.58622 1.29032 10.4209 1.29032C15.2072 1.29032 19.1306 5.16532 19.1306 10C19.1306 14.7863 15.2556 18.7097 10.4209 18.7097ZM14.2435 7.20564L11.4491 10L14.2435 12.7944C14.433 12.9839 14.433 13.2903 14.2435 13.4798L13.9007 13.8226C13.7112 14.0121 13.4048 14.0121 13.2153 13.8226L10.4209 11.0282L7.62654 13.8226C7.43703 14.0121 7.13058 14.0121 6.94106 13.8226L6.59832 13.4798C6.4088 13.2903 6.4088 12.9839 6.59832 12.7944L9.39267 10L6.59832 7.20564C6.4088 7.01613 6.4088 6.70968 6.59832 6.52016L6.94106 6.17742C7.13058 5.9879 7.43703 5.9879 7.62654 6.17742L10.4209 8.97177L13.2153 6.17742C13.4048 5.9879 13.7112 5.9879 13.9007 6.17742L14.2435 6.52016C14.429 6.70968 14.429 7.01613 14.2435 7.20564Z" fill="#F1173A" />
  </svg>
);

export default QErrorCircle;
