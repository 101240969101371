import BackgroundImg from 'assets/images/defaultLoginImage.png';
import QModalBackgroundImg from 'components/atoms/QModalBackgroundImg';
import QModalBodyLeft from 'components/atoms/QModalBodyLeft';
import QModalBodyRight from 'components/atoms/QModalBodyRight';
import QModalContainer from 'components/atoms/QModalContainer';
import QModalHeader from 'components/atoms/QModalHeader';
import { NextStepsKeys, Steps, useRecoverPasswordContext } from 'context/RecoverPasswordContext';
import React from 'react';
import FirstStep from './steps/FirstStep';
import FourthStep from './steps/FourthStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import './styles.css';

export interface QForgetPasswordModalProps {
  open: boolean
  fnCallBack: React.Dispatch<React.SetStateAction<boolean>>
}

type StepsComponent = {
  [k in NextStepsKeys]: () => JSX.Element
}

const StepsComponents: StepsComponent = {
  FIRST: FirstStep,
  SECOND: SecondStep,
  THIRD: ThirdStep,
  FOURTH: FourthStep
}

const QForgetPasswordModal = (Props: QForgetPasswordModalProps) => {
  const { open, fnCallBack } = Props;
  const { step, setStep } = useRecoverPasswordContext();

  const CurrentStep = StepsComponents[step as unknown as NextStepsKeys];

  return (
    <QModalContainer open={open} type="Forget">
      <QModalHeader fnCallback={() => {
        fnCallBack(false);
        setStep(Steps.FIRST);
      }}
      />

      <div className="QBodyContainer">
        <QModalBodyLeft>
          <QModalBackgroundImg src={BackgroundImg} />
        </QModalBodyLeft>

        <QModalBodyRight>
          <CurrentStep />
        </QModalBodyRight>
      </div>
    </QModalContainer>
  );
};

export default QForgetPasswordModal;
