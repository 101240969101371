import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorsState, InputError } from "entities/error";

const initialState: ErrorsState = {
  inputErrors: {},
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addInputError: (state, action: PayloadAction<InputError>) => {
      state.inputErrors = { ...state.inputErrors, ...action.payload }
    },
    removeInputError: (state, action: PayloadAction<string>) => {
      state.inputErrors = Object.keys(state.inputErrors).reduce((obj: InputError, key: string) => {
        const newObj = obj;

        if (action.payload !== key) {
          newObj[key] = state.inputErrors[key]
        }
        return newObj;
      }, {});
    },
    clearInputErrors: (state) => {
      state.inputErrors = {}
    }
  }
});

export const {
  addInputError,
  clearInputErrors,
  removeInputError
} = errorsSlice.actions;

export default errorsSlice.reducer;
