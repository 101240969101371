import QCloseButton from 'assets/icons/QCloseButton';
import classNames from 'classnames';
import React from 'react';
import './styles.css';

export interface QModalCloseButtonProps {
  fnCallback?: () => void,
  className?: string,
}

const QModalCloseButton = (Props: QModalCloseButtonProps) => {
  const { fnCallback, className } = Props;

  return (
    <div
      onMouseDown={fnCallback}
      onKeyPress={fnCallback}
      role="button"
      tabIndex={0}
      className={classNames('QModalCloseButton', className)}
    >
      <QCloseButton />
    </div>
  );
};

export default QModalCloseButton;
