import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OfferType } from '../../entities/checkout';

type CheckoutStateType = {
  offer: OfferType | null
}

const initialState: CheckoutStateType = {
  offer: null,
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setOffer: (state, action: PayloadAction<OfferType>) => {
      state.offer = action.payload;
    },
  },
});

export const { setOffer } = checkoutSlice.actions;

export default checkoutSlice.reducer;
