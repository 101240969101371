import { Box } from "@mui/material";
import QModalButton from "components/atoms/QModalButton";
import QModalInput from "components/atoms/QModalInput";
import QModalTitle from "components/atoms/QTypography/QModalTitle";
import { useRecoverPasswordContext } from "context/RecoverPasswordContext";
import { useToastContext } from "context/ToastContext";
import AuthApi from 'services/auth';

const ThirdStep = () => {
  const { goToNextStep, code, email, } = useRecoverPasswordContext();
  const { openToast } = useToastContext();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const password = formData.get('password') as string;
    const confirmPassword = formData.get('confirmPassword') as string;

    if (password !== confirmPassword) {
      openToast({
        message: 'As senhas não correspondem',
        type: 'error'
      });
      return;
    }

    try {
      await AuthApi.resetPassword(email, code, password);
      goToNextStep();
    } catch (error) {
      openToast({
        message: 'Ocorreu um erro ao tentar confirmar o código.',
        type: 'error'
      });
    }
  };

  return (
    <>
      <QModalTitle style={{ marginBottom: 30 }}>
        Esqueceu sua senha?
      </QModalTitle>

      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <QModalInput type="password" name="password" required placeholder="Nova senha" />
          <QModalInput type="password" name="confirmPassword" required placeholder="Confirme a sua senha" />
        </Box>

        <QModalButton type="submit" style={{ marginTop: 20 }}>
          Redefinir senha
        </QModalButton>
      </form>
    </>
  );
};

export default ThirdStep;
