import 'reset-css/reset.css';
import 'assets/global.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { initFacebookSdk } from 'utils/facebook/index.js';
import TagManager from 'react-gtm-module'
import App from './App';
import reportWebVitals from './reportWebVitals';

import './texts'

const gtmIds = process.env.REACT_APP_GTM_ID;

if (gtmIds) {
  gtmIds.split(',').forEach((gtmId: string) => {
    TagManager.initialize({
      gtmId
    });
  });
}

declare global {
  interface Window {
    FB: any;
    auth2: any;
    gapi: any;
  }
}

initFacebookSdk();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
