import React, { ReactNode } from 'react';
import './styles.css';

export interface QModalDescriptionsProps {
  children: ReactNode,
  style?: React.CSSProperties | undefined
}

const QModalDescriptions = (Props: QModalDescriptionsProps) => {
  const { children, style } = Props;

  return (
    <h1 className="QModalDescriptions" style={{ ...style }}>
      {children}
    </h1>
  );
};

export default QModalDescriptions;
