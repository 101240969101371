const PRODUCTS_PLACEHOLDER = [
  {
    cover: 'https://s3.amazonaws.com/assets.qcarreiras-hmg.com/static/courses/CardLideranca.png',
    name: 'Liderança',
    tags: [
      'Negócios',
      'Empreendedorismo',
    ],
    duration: 25,
    students: 100,
    partner: 'Estácio',
    id: "e99ca523-77c5-4164-8c6c-f83e5351fbec",
  },
  {
    cover: 'https://s3.amazonaws.com/assets.qcarreiras-hmg.com/static/courses/mercadofinanceiro.png',
    name: 'Primeiros Passos no Mercado Financeiro',
    tags: [
      'Mercado Financeiro',
    ],
    duration: 40,
    students: 100,
    partner: 'Estácio',
    id: "233a83a7-bfc0-4541-bc97-84308ec5ea87",
  },
  {
    cover: 'https://s3.amazonaws.com/assets.qcarreiras-hmg.com/static/courses/gp.png',
    name: 'Gerenciamento de Projetos',
    tags: [
      'Negócios',
      'Empreendedorismo',
    ],
    duration: 38,
    students: 300,
    partner: 'Estácio',
    id: "abb3be1f-cf5b-43e8-bf0f-134508556190",
  },
  {
    cover: 'http://via.placeholder.com/640x640',
    name: 'Lorem Ipsum',
    tags: [
      'Gestão',
      'Marketing',
    ],
    duration: 25,
    students: 100,
    partner: 'IBMEC',
    id: "e99ca523-77c5-4164-8c6c-f83e5351fbec",
  },
  {
    cover: 'http://via.placeholder.com/640x640',
    name: 'Lorem Ipsum',
    tags: [
      'Desenvolvimento Pessoal',
    ],
    duration: 40,
    students: 100,
    partner: 'Estácio',
    id: "233a83a7-bfc0-4541-bc97-84308ec5ea87",
  },
  {
    cover: 'http://via.placeholder.com/640x640',
    name: 'Lorem Ipsum',
    tags: [
      'Tech',
    ],
    duration: 38,
    students: 300,
    partner: 'Estácio',
    id: "abb3be1f-cf5b-43e8-bf0f-134508556190",
  },
];

const CATEGORIES_PLACEHOLDER = [
  {
    name: 'Todos os assuntos',
    value: 'any',
    url: null
  },
  {
    name: 'Gestão',
    value: 'Gestão',
    url: '/cursos-de-gestao'
  },
  {
    name: 'Mercado Financeiro',
    value: 'Mercado Financeiro',
    url: '/cursos-de-investimento'
  },
  {
    name: 'Marketing',
    value: 'Marketing',
    url: '/cursos-de-marketing'
  },
  {
    name: 'Desenvolvimento Pessoal',
    value: 'Desenvolvimento Pessoal',
    url: '/cursos-de-desenvolvimento-pessoal'
  },
  {
    name: 'Tech',
    value: 'Tech',
    url: '/cursos-de-tecnologia'
  }
]

export { PRODUCTS_PLACEHOLDER, CATEGORIES_PLACEHOLDER };
