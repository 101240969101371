/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QCheckCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="currentColor" d="M10 0C4.47714 0 0 4.47714 0 10C0 15.5229 4.47714 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0ZM10.0002 18.7096C5.21534 18.7096 1.2905 14.8364 1.2905 9.99996C1.2905 5.21516 5.16369 1.29028 10.0002 1.29028C14.785 1.29028 18.7099 5.16347 18.7099 9.99996C18.7099 14.7848 14.8367 18.7096 10.0002 18.7096ZM8.43387 14.8416L15.7113 7.62257C15.901 7.43439 15.9022 7.12801 15.714 6.9383L15.3733 6.59475C15.1851 6.40503 14.8787 6.40382 14.689 6.59201L8.09863 13.1295L5.31735 10.3258C5.12916 10.136 4.82279 10.1348 4.63307 10.323L4.28953 10.6638C4.09981 10.852 4.0986 11.1583 4.28678 11.3481L7.7496 14.8389C7.93779 15.0286 8.24416 15.0298 8.43387 14.8416Z" />
  </svg>
);

export default QCheckCircle;
