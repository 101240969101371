import React from 'react';
import QLoginOfferBenefits from 'components/atoms/QLoginOfferBenefits';
import './styles.css';
import { OfferType } from 'entities/checkout';
import { formatPrice } from 'utils/price';

export interface QModalOfferInformationProps {
  offer: OfferType
}

const QModalOfferInformation = (Props: QModalOfferInformationProps) => {
  const { offer } = Props;

  if (!offer) {
    return null;
  }

  const hasDiscount = offer.price.cents < offer.referencePrice.cents;

  return (
    <div className="QModalOfferInformation">
      <span className="QOfferTitle">{offer.name}</span>
      <span className="QOfferOldValue">
      {hasDiscount ? (
        <>
          de &nbsp;
          <span className="Decorated">{formatPrice(offer.referencePrice.cents)}</span>
          &nbsp; por
        </>
      ) : null}
       </span>
      <span className="QOfferNewValue">{formatPrice(offer.price.cents)}</span>
      {offer?.benefits && <QLoginOfferBenefits benefits={offer.benefits} />}
    </div>
  );
};

export default QModalOfferInformation;
