/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QCheckoutProtected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.3257 2.96856C13.0837 2.96856 10.5999 2.04229 8.49965 0C6.39959 2.04229 3.91595 2.96856 0.674165 2.96856C0.674165 8.28709 -0.426343 15.9059 8.49959 19C17.4262 15.9059 16.3257 8.28715 16.3257 2.96856ZM7.85136 12.3277L5.24415 9.72013L6.41149 8.55284L7.85136 9.99303L10.5881 7.25626L11.7554 8.42354L7.85136 12.3277Z" fill="#23BE87" />
  </svg>
);

export default QCheckoutProtected;
