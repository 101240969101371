/* eslint react/jsx-props-no-spreading: 0 */
import React from 'react';

const QGoogleLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.6 9.20001C17.6 8.60001 17.5 7.90001 17.4 7.40001H9V10.9H13.8C13.6 12 13 12.9 12 13.6V15.9H15C16.7 14.3 17.6 11.9 17.6 9.20001Z" fill="#4285F4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 18C11.4 18 13.5 17.2 15 15.8L12 13.6C11.2 14.1 10.2 14.5 9 14.5C6.7 14.5 4.7 12.9 4 10.8H1V13C2.4 16 5.5 18 9 18Z" fill="#34A853" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4 10.7C3.8 10.2 3.7 9.6 3.7 9C3.7 8.4 3.8 7.8 4 7.3V5H1C0.4 6.2 0 7.6 0 9C0 10.4 0.3 11.8 1 13L4 10.7Z" fill="#FBBC05" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9 3.6C10.3 3.6 11.5 4.1 12.4 4.9L15 2.3C13.5 0.9 11.4 0 9 0C5.5 0 2.4 2 1 5L4 7.3C4.7 5.2 6.7 3.6 9 3.6Z" fill="#EA4335" />
  </svg>
);

export default QGoogleLogo;
