import React, { ChangeEvent, ReactElement } from 'react';
import QChecked from 'assets/icons/QChecked';
import './styles.css';

export interface QModalCheckboxProps {
  children?: ReactElement[] | ReactElement,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  required?: boolean
}

const QModalCheckbox = (Props: QModalCheckboxProps) => {
  const { children, onChange, required } = Props;

  return (
    <div className="QModalCheckboxContainer">
      <div className="QModalCheckboxDisplay">
        <input type="checkbox" required={required} className="QModalCheckbox" onChange={onChange} />
        <QChecked className="QModalCheckboxIcon" />
      </div>
      {children}
    </div>
  );
};

export default QModalCheckbox;
