import { Box } from "@mui/material";
import QEmailReSent from "assets/icons/QEmailReSent";
import QModalDescriptions from "components/atoms/QTypography/QModalDescriptions";
import QModalTitle from "components/atoms/QTypography/QModalTitle";
import { Steps, useRecoverPasswordContext } from "context/RecoverPasswordContext";
import { useEffect } from "react";
import { useAppDispatch } from "stores";
import { setShowForgotModal } from "stores/modals";

const FourthStep = () => {
  const dispatch = useAppDispatch;
  const { setStep } = useRecoverPasswordContext();
  const descriptionText = 'Estamos direcionando você para a melhor experiência de aprendizado online...';

  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowForgotModal());
      setStep(Steps.FIRST);
    }, 3000);
  }, []);

  return (
    <>
      <Box className="QEmailReSentIcon">
        <QEmailReSent />
      </Box>
      <QModalTitle style={{ alignSelf: 'center' }}>
        Senha alterada com sucesso!
      </QModalTitle>
      <QModalDescriptions style={{ marginTop: 8, textAlign: 'center', fontSize: 14 }}>
        {descriptionText}
      </QModalDescriptions>
    </>
  );
};

export default FourthStep;
