import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiErrorType } from 'entities/error';
import { Order } from 'entities/orders';
import OrdersAPI from 'services/orders';
import { hideLoading, showLoading } from 'stores/modals';

export type OrdersStoreType = {
  list: Order[],
  order: Order | null;
}

export interface ViewInterface {
  contentId: string;
}

const initialState: OrdersStoreType = {
  list: [],
  order: null
};

export const getOrders = createAsyncThunk<
  Order[],
  void,
  {
    rejectValue: ApiErrorType,
  }
>(
  'orders/list',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      OrdersAPI.getOrders()
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const getOrder = createAsyncThunk<
  Order,
  string,
  {
    rejectValue: ApiErrorType,
  }
>(
  'orders/get',
  async (orderId, thunkAPI) => {
    thunkAPI.dispatch(showLoading());
    return (
      OrdersAPI.getOrder(orderId)
        .then((response) => {
          thunkAPI.dispatch(hideLoading());
          return response.data;
        })
        .catch((error) => {
          thunkAPI.dispatch(hideLoading());
          return thunkAPI.rejectWithValue(error.response.data);
        })
    );
  },
);

export const coursesSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.fulfilled, (state, action) => {
        state.list = action.payload;
      });
    builder
      .addCase(getOrder.fulfilled, (state, action) => {
        state.order = action.payload;
      });
  },
});

export default coursesSlice.reducer;
